import React, { useEffect, useState } from 'react';
import './App.scss';
import {Header} from "./components/Header/Header";
import {
  BrowserRouter, Route, Router, Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Footer } from './components/Footer/Footer';
import { HeaderMobile } from './components/HeaderMobile/HeaderMobile';
import Home from './components/Home/Home';
import ScrollToTopOnRouteChange from './components/ScrollToTopOnRouteChange';
import { Page404 } from './components/Page404/Page404';
import { Quiz } from './components/Quiz/Quiz';
import { useGetAllData } from './hooks/useGetAllData';
import { nftContractConfig } from './contractConfigs/configs';
import { setCanMint } from "./utils/contractDataUtil";
import {Lightpaper} from "./pages/Lightpaper/Lightpaper";
import {Listings} from "./pages/Listings/Listings";

const newHistory = createBrowserHistory();

function App() {
  const [isBelow1050, setIsBelow1050] = useState(false);

  const contractData = useGetAllData(nftContractConfig);
  console.log(contractData, 'from contract contractData');
  setCanMint(contractData);
  console.log(contractData, 'modified contract contractData');

  const checkScreenWidth = () => {
    const screenWidth = window.innerWidth;
    setIsBelow1050(screenWidth < 1050);
  };
  useEffect(() => {
    // Add event listener on component mount to check screen width
    window.addEventListener('resize', checkScreenWidth);

    // Initial check on component mount
    checkScreenWidth();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []); // Empty dependency array to run effect only once on mount
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTopOnRouteChange />
        <Router history={newHistory}>
          {isBelow1050 ? <HeaderMobile contractData={contractData} /> : <Header contractData={contractData} />}
          <Switch>
            <Route exact path="/" component={() => <Home contractData={contractData} />} />
            <Route path="/apply" component={Quiz} />
            <Route path="/lightpaper" component={Lightpaper} />
            <Route path="/listings" component={Listings} />
            <Route path="*" component={Page404} />
          </Switch>
          <Footer contractData={contractData} />
        </Router>
      </BrowserRouter>
    </div>
  );
}

export default App;

import {useEffect, useState} from 'react';

import {Quiz} from "../../Quiz/Quiz"

import mobileStake from '../../../assets/mobile-box.png';
import play from '../../../assets/play-the-game.png';
import coin from '../../../assets/coin.gif';
import stake from '../../../assets/stake.gif';
import monkey from '../../../assets/monkey.png';
import flowMobile from '../../../assets/flow-mobile.gif';

import './StakeMine.scss'

import monkeyHold from '../../../assets/monkey-hold.svg';
import stAbi from "../../../env/404stAbi.json";
import silverLine from '../../../assets/silver-line.svg';
import {Link} from "react-router-dom";
// import {useCallback, useEffect, useState} from 'react';
// import {mainnet} from "wagmi/chains";
// import {useAccount, useReadContract, useWriteContract} from "wagmi";
// import { useGetBalance } from '../../../hooks/useGetBalance'
// import {useGetStakes} from '../../../hooks/useGetStakes'

// const contractConfig = {
//     abi: stAbi,
//     address: '0x52cD03F59E8cb8563Ee11A36Ea8380525e90Fdcc',
//     chainId: 11155111,
//     // chainId: mainnet.id,
// }
//
// interface Stake {
//     id: bigint;
//     amount: bigint;
//     lvl: bigint;
//     time: bigint;
// }
//
// interface Props {
//     allStakes: Stake[];
// }

export const StakeMine = () => {
  // // responsive
  const [isMobile, setIsMobile] = useState(false);
  //
  // // get wallet address
  // const { address } = useAccount()
  //
  // // claim
  // const [validationError, setValidationError] = useState('')
  // const [successMessage, setSuccessMessage] = useState('')
  //
  // // estimate claim
  // const [estimatedStakeClaim, setEstimatedStakeClaim] = useState(null);
  //
  // // stake
  // const [stakeAmount, setStakeAmount] = useState(0);
  // const handleStakeAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     console.log('stake amount value', event.target.value);
  //     setStakeAmount(Number(event.target.value));
  // };
  //
  // // Get all stakes for wallet address
  // const allStakes = useGetStakes(contractConfig)
  //
  // const {balance, prettyBalance} = useGetBalance(contractConfig)
  //
  // console.log("allStakes", allStakes);
  // console.log("balance", balance);
  // console.log("prettyBalance", prettyBalance);
  //
  // // Define writContract function
  // const { data: hash, writeContract, error, isPending } = useWriteContract()
  //
  // // Handle claim
  // const claim = useCallback((stakeId: bigint) => {
  //     setValidationError('')
  //     if (!stakeId) {
  //         setValidationError('No stake to claim')
  //         return
  //     }
  //     if (!address) {
  //         setValidationError('Connect your wallet')
  //         return
  //     }
  //     try {
  //         // @ts-ignore
  //         writeContract({
  //             ...contractConfig,
  //             functionName: 'claim',
  //             args: [stakeId],
  //             value: 0,
  //         })
  //         setSuccessMessage(`Claimed 2 ERC20 tokens and 2 ERC404 NFTs tokens...`)
  //         console.log('claimed stakeId', stakeId)
  //     } catch (error)  {
  //         console.error('error claiming', error)
  //     }
  // },[writeContract, address])
  //
  //
  // // Handle stake
  // const handleStake = useCallback(() => {
  //     setValidationError('')
  //     console.log('stake amount', stakeAmount);
  //     if (!stakeAmount) {
  //         setValidationError('Need to enter amount to stake')
  //         return
  //     }
  //     if (!address) {
  //         setValidationError('Connect your wallet')
  //         return
  //     }
  //
  //     const amountForStake = BigInt(stakeAmount * 10**18);
  //     console.log('amount for stake', amountForStake)
  //     try {
  //         // @ts-ignore
  //         writeContract({
  //             ...contractConfig,
  //             functionName: 'stake',
  //             args: [amountForStake],
  //             value: 0,
  //         })
  //         setSuccessMessage(`Staked ERC20 tokens ${stakeAmount}...`)
  //         console.log('staked amount', stakeAmount)
  //     } catch (error)  {
  //         console.error('error staking', error)
  //     }
  // },[writeContract, address, stakeAmount])


  // handle estimate claim
  // const estimateClaim = (stakeId: number) => {
  //     console.log("stakeId", stakeId);
  //
  //     // @ts-ignore
  //     const {data: estimatedClaim} = useReadContract({
  //         ...contractConfig,
  //         functionName: 'estimateClaim',
  //         args: [address, stakeId],
  //     });
  //
  //     console.log("estimatedClaim", estimatedClaim);
  //
  //     setEstimatedStakeClaim(estimatedClaim);
  // }

  const checkScreenWidth = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth < 600);
  };
  //
  useEffect(() => {
    // Add event listener on component mount to check screen width
    window.addEventListener('resize', checkScreenWidth);

    // Initial check on component mount
    checkScreenWidth();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []); // Empty dependency array to run effect only once on mount
  return (
    <div className='stake-mine-wrapper'>
      <div className="width-container">
        <div className="separator"/>
        <div className='stake-mine-gif'>
          {
            isMobile ?
              <img src={flowMobile} alt="flowMobile"/>
              :
              <img src={stake} alt="stake"/>
          }
        </div>
        <div className="separator"/>
      </div>
      <Link to='/apply'>
        <div className='stake-mine-image'>
          <img src={isMobile ? mobileStake : play} alt="play"/>
        </div>
      </Link>


      <div className='stake-mine-404st-token'>
        <img src={coin} alt="coin"/>
        <div className='stake-mine-404st-text'>
          <div className='stake-mine-404st-title'>404ST TOKEN</div>
          <div className='stake-mine-404st-desc'>Claim your tokens with NFT here and start your journey with 404ST!
          </div>
        </div>
        <img src={monkey} alt="monkey"/>
      </div>
      <div className="separator"/>
    </div>
  );
};

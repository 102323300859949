import React, {useState, useEffect, useRef} from 'react';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from 'react-dnd-touch-backend'
import './Quiz.scss';
import {useIsMobile} from "../../hooks/useIsMobile";

import dragImage from '../../assets/drag.png'

const ItemTypes = {
    QUESTION: 'question',
};

const questionTitles = [
    'DeFi Projects',
    'Memecoins',
]

const questionsData = [
    [
        {name: 'Ethereum', image: 'https://404st.io/shared-content/ethereum.svg', check: false, sort: 3},
        {
            name: 'Golem',
            image: 'https://404st.io/shared-content/golem-network-tokens-glm-logo.svg',
            check: false,
            sort: 2
        },
        {name: 'Protocol0x', image: 'https://404st.io/shared-content/0x-zrx-logo.svg', check: false, sort: 6},
        {name: 'MakerDAO', image: 'https://404st.io/shared-content/maker-mkr-logo.svg', check: false, sort: 8},
        {name: 'Chainlink', image: 'https://404st.io/shared-content/chainlink-link-logo.svg', check: false, sort: 1},
        {name: 'Synthetix', image: 'https://404st.io/shared-content/synthetix-snx-logo.svg', check: false, sort: 4},
        {name: 'Compound', image: 'https://404st.io/shared-content/compound-comp-logo.svg', check: false, sort: 9},
        {name: 'TerraLUNA', image: 'https://404st.io/shared-content/terra-luna.svg', check: false, sort: 5},
        {name: 'Uniswap', image: 'https://404st.io/shared-content/uniswap-uni-logo.svg', check: false, sort: 7},
    ],
    [
        {name: 'Genesis', image: 'https://404st.io/shared-content/sog.svg', check: false, sort: 2},
        {name: 'RarePepe', image: 'https://404st.io/shared-content/pepe.svg', check: false, sort: 3},
        {name: 'CryptoKitties', image: 'https://404st.io/shared-content/crypto-kitties.svg', check: false, sort: 6},
        {
            name: 'Decentraland',
            image: 'https://404st.io/shared-content/decentraland-mana-logo.svg',
            check: false,
            sort: 8
        },
        {name: 'Dogecoin', image: 'https://404st.io/shared-content/dogecoin-doge-logo.svg', check: false, sort: 1},
        {name: 'NBATopShot', image: 'https://404st.io/shared-content/nba.svg', check: false, sort: 4},
        {name: 'BAYC', image: 'https://404st.io/shared-content/bayc.svg', check: false, sort: 5},
        {name: 'CryptoPunks', image: 'https://404st.io/shared-content/cryptopunks.svg', check: false, sort: 7},
        {name: 'Brett', image: 'https://404st.io/shared-content/brett.svg', check: false, sort: 9},
    ]
];

const Question = ({question}: { question: any }) => {
    const [{isDragging}, drag, preview] = useDrag({
        type: ItemTypes.QUESTION,
        item: {name: question.name},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    return (
        <div className="question_preview" ref={preview}>
            <div className="question_wrap" ref={drag}>
                <div className={`question ${isDragging ? 'isDrag' : question.check ? 'check' : ''}`}
                     data-question={question.name}>
                    {isDragging && <img src={dragImage} alt="Drag" className="drag_image"/>}
                    <img src={question.image} alt={question.name}  className="question_image" />
                    <span>{question.name.charAt(0).toUpperCase() + question.name.slice(1)}</span>
                </div>
                {
                    question.check && <div className="tick"><i className="icon ion-checkmark-round"></i></div>
                }
            </div>
        </div>
    );
};

const QuestionHolder = ({question, onDrop, questionIndex, index}: {
    question: any;
    onDrop: any;
    questionIndex: any;
    index: any;
}) => {
    const [{isOver}, drop] = useDrop({
        accept: ItemTypes.QUESTION,
        drop: (item: any) => {
            if (!question.check)
                onDrop(item.name, question.name, questionIndex)
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div className={`question_holder ${question.name} ${question.check ? 'question_holder_answer' : ''}`} ref={drop}
             data-question={question.name}>
            <div className='question_result'>{2010 + index}</div>
            <div className="question_answer">
                <img
                    src={`${question.check ? question.image : ''}`}
                    alt={question}
                    className={question.check ? 'scale' : ''}
                />
            </div>
        </div>
    );
};

export const Quiz = () => {
    const [questions, setQuestions] = useState<any>(questionsData)
    const [time, setTime] = useState<any>('0 seconds');
    const correctCount = useRef(0)
    const timer = useRef<any>(undefined)
    const [showModal, setShowModal] = useState<any>('intro');
    const isMobile = useIsMobile()

    const startTimer = () => {
        const startTime = new Date();
        timer.current = setInterval(() => {
            const currentTime = new Date();
            setTime(`${Math.floor((currentTime.getTime() - startTime.getTime()) / 1000)} seconds`);
        }, 1000);
    };

    const stopTimer = () => {
        clearInterval(timer.current);
        timer.current = undefined
    };

    const handleDrop = (droppedQuestion: any, question: any, questionIndex: any) => {

        if (droppedQuestion === question) {
            correctCount.current += 1;
            setQuestions((prevQuestions: any) => {
                const _questions = questions[questionIndex]
                const index = _questions.findIndex((pln: any) => question === pln.name)
                console.log(index);
                if (index > -1) {
                    const newQuestions = [...prevQuestions];
                    newQuestions[questionIndex][index].check = true;
                    return newQuestions;
                }
                return prevQuestions
            })
            const element = document.querySelector(`.question[data-question=${droppedQuestion}]`)
            if (element) {
                element.classList.add('answered');
            }
            if (correctCount.current === questions.flat().length) {
                setShowModal('winner');
                stopTimer();
            }
        }
    };

    const startGame = () => {
        startTimer();
        stopTimer();
        setShowModal(null);
    }

    const resetGame = () => {
        correctCount.current = 0;
        setQuestions(questions.map((question: any) => question.map((item: any) => ({...item, check: false}))))
        setTime('0 seconds');
        setShowModal('intro');
        startTimer();
        document.querySelectorAll('.question').forEach(Elem => {
            Elem.classList.remove('answered');
        })
    };

    console.info(isMobile)

    const QuizContent = () => (
        <div className={`${!showModal ? 'quiz' : 'quiz default'}`}>
            {!showModal && (
                <div className="timer">{time}</div>
            )}

            {showModal === 'intro' && (
                <div className='overlay'>
                    <div className="modal">
                        <div className="modal_inner">
                            <div className="modal_inner__title">
                                <h2>Apply for Ambassador</h2>
                            </div>
                            <div className="modal_inner__text">
                                <p>Drag the projects in the correct order as fast as you can!</p>
                            </div>
                            <div className="modal_inner__image">
                                <img src="https://404st.io/shared-content/quiz-gif.gif" alt="Intro"/>
                            </div>
                            <div className="modal_inner__cta c_modal">
                                <button className="st" onClick={startGame}>
                                    Lets go!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showModal === 'winner' && (

                <div className='overlay'>
                    <div className="modal">
                        <div className="modal_inner">
                            <div className="modal_inner__title">
                                <h2>
                                    Almost there!
                                </h2>
                            </div>
                            <div className="modal_inner__text">
                                <p>Click on "Share on X" button to complete the process.</p>
                            </div>
                            <div className="modal_inner__cta">
                                <button className="tw">
                                    <a href="http://twitter.com/share?text=@404st_io%0A%0AReady to take on the challenge?%0A%0A🔁 Repost this to spread the word and invite others to step up and make their mark!%0A%0A&url=https://404st.io%0A%0AEnjoy the ride%0A%0Ahttps://twitter.com/404st_io/status/1790876602210897934/video/1"
                                       target="_blank"
                                    >
                                    Tweet
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="questions">
                <div className="questions_stars">
                    <div className="stars"></div>
                    <div className="twinkling"></div>
                    <div className="clouds"></div>
                </div>
                <div className="questions_container">
                    <div className="questions_container__title">
                        <h1>Apply for Ambassador</h1>
                    </div>
                    <div className="questions_container__title">
                        <h3>Drag the projects into the correct order</h3>
                    </div>
                    {questions.map((question: any, questionIndex: any) => (
                        <>
                            <div className="question_title">{questionTitles[questionIndex] || ''}</div>
                            <div className="questions_container__questions" key={questionIndex}>
                                {question.map((question: any, index: any) => (
                                    <Question key={index} question={question}/>
                                ))}
                            </div>
                            <div className="questions_container__quiz">
                                {[...question].sort((a: any, b: any) => a.sort - b.sort).map((question: any, index: any) => (
                                    <QuestionHolder key={index} index={index} question={question}
                                                    onDrop={handleDrop} questionIndex={questionIndex}/>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )


    return (
        <div className='page-wrapper'>
            {!isMobile && <DndProvider backend={HTML5Backend}><QuizContent /></DndProvider>}
            {isMobile && <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}><QuizContent /></DndProvider>}
        </div>
    );
};

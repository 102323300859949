import React, { FC } from 'react';
import { scrollToSection } from '../../utils';

import dextools from '../../assets/dextools.svg';
import opensea from '../../assets/icons8-opensea.svg';
import telegram from '../../assets/icons8-telegram.svg';
import twitter from '../../assets/icons8-twitterx.svg';
import logo from '../../assets/footer-logo.png';
import { ContractData } from '../types/types';

import './Footer.scss'

type Props = {
  contractData: ContractData;
};

export const Footer: FC<Props> = ({ contractData }) => {
  return (
    <div className='footer-wrapper'>
      <div className='footer-content'>
        <div className="width-container">
          {/*// @ts-ignore*/}
          <div className='footer-links'>
            {/*{contractData && contractData.showMint && (<div className='footer-content-link' onClick={() => scrollToSection('mint')}>MINT</div>)}*/}
            <div className="separator-small" />
            <div className='footer-content-link' onClick={() => scrollToSection('team')}>ABOUT US</div>
            <div className="separator-small" />
            <div className='footer-content-link' onClick={() => scrollToSection('header')}>CONNECT WALLET</div>
          </div>
          <img src={logo} alt="logo"/>
        </div>
      </div>

      <div className='footer-rights'>
        <div className="width-container">
          <div className='footer-rights-text'>COPYRIGHT - 2024 - ALL RIGHTS RESERVED.</div>
          <nav className="footer-socials-wrapper">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={dextools} alt="Dextools"/>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={opensea} alt="OpenSea"/>
            </a>
            <a href="https://t.me/Monkez404STportal" target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="Telegram"/>
            </a>
            <a href="https://twitter.com/404st_io" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter"/>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export const setCanMint = (contractData: any) => {
    if (!contractData) {
        return contractData;
    }

    const isNFTPaused = contractData && contractData.isPaused;
    const isAddressReserved = contractData && contractData.isAddressReserved;
    const isNFTReservationInProgress = contractData && contractData.isReservationInProgress;
    const isNFTReservationEnded = contractData && contractData.isReservationEnded;

    contractData.showMint = (
            // Address is reserved and NFT contract is not paused and NFT reservation is in progress and NFT reservation is not ended
            // This will allow only reserved users to use minting functionality while reservation period is in progress
            isAddressReserved && !isNFTPaused && isNFTReservationInProgress && !isNFTReservationEnded
        )
        || (
            // Address does not need to be reserved and NFT contract is not paused and NFT reservation is not in progress and NFT reservation is ended
            // This will allow all users to use minting functionality after reservation period is over
            !isNFTPaused && (!isNFTReservationInProgress && isNFTReservationEnded)
        );

    return contractData;
}

import monkeyAsset from '../../assets/monkey-body.png'
import processVerticalAsset from '../../assets/process-vertical.png'
import coinAsset from '../../assets/coin.gif'
import mobileStake from "../../assets/mobile-box.png";
import play from "../../assets/play-the-game.png";
import {useIsMobile} from "../../hooks/useIsMobile";

export const Lightpaper = () => {
    const isMobile = useIsMobile();

    return (
        <div className='page-wrapper'>
            <div className="text-content-container">
                <h1 className='center-text title-xl margin-t-l'>Lightpaper</h1>

                <p className='text-l margin-t-xl'>
                    404ST is a project aimed at establishing a vibrant and engaged community through the issuance of OG
                    NFTs and
                    404ST protocol. This whitepaper outlines our complete project model, starting with the release of an
                    OG NFT
                    collection followed by the introduction of a modified version of the 404 protocol, combining ERC20
                    tokens,
                    NFTs & Staking.
                </p>

                <p className='margin-t-l text-m accent-blue-color'>
                    404ST aims to revolutionize community engagement and collaboration by leveraging the unique
                    properties of NFTs and ERC20 tokens. We begin by launching an OG NFT collection to establish the
                    foundation of our community, followed by the introduction of a modified version of the 404 protocol,
                    where each token corresponds to one NFT through the staking period.
                </p>

                <div className="center-image margin-t-l">
                    <img className='default-image' src={monkeyAsset} alt="Monkey"/>
                </div>

                <p className='margin-t-l text-m accent-blue-color'>
                    The OG NFT collection consists of 2000 unique tokens, each representing a distinct digital asset.
                    These tokens will be released as NFTs on a separate contract, serving as the initial offering to
                    kickstart our community. Also 1 holder of OG NFT here will be included in the rest of the project as
                    a team member.
                </p>

                <p className='text-l margin-t-xl gradient-text'>
                    We will introduce a modified version of the 404 protocol, wherein each token corresponds to one NFT
                    through a staking process. This modified protocol ecosystem will include a different approach that
                    will point to the NFT claim procedure with required staking timestamp.
                </p>

                <ul>
                    <li>
                        <p className='text-m'>
                            EVERYBODY CAN BUY ERC20 TOKENS
                        </p>
                    </li>
                    <li>
                        <p className='text-m'>
                            "High-Level Document: $? Smart Contract"
                        </p>
                    </li>
                </ul>

                <br/>
                <br/>

                <ul className='formatted-list'>
                    <li>
                        <p className='text-m gradient-text'>
                            ## OVERVIEW
                        </p>

                        <ul>
                            <li>
                                <p className='text-m'>
                                    The $? smart contract is an extension of the ERC404 protocol. It introduces
                                    additional functionality for staking tokens and claiming rewards based on the staked
                                    amount and duration. The contract also includes a mechanism for generating random
                                    numbers, which is used to determine the level of a stake.
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p className='text-m gradient-text'>
                            ## CONTRACT DETAILS
                        </p>

                        <ul>
                            <li>
                                <p className='text-m'>
                                    Initialization/Deployment:
                                </p>
                            </li>

                            <ul>
                                <li>
                                    <p className='text-m'>
                                        On contract initialization/deployment, necessary parameters such as the name,
                                        symbol, decimals, maximum total supply, initial owner, initial mint recipient,
                                        and the Uniswap V3 router address are set. It also sets the initial owner as
                                        exempt from ERC721 transfers, which means none of the NFT tokens will be minted
                                        on deployment.
                                    </p>
                                </li>
                            </ul>
                        </ul>
                    </li>

                    <li>
                        <p className='text-m gradient-text'>
                            Transfer (Buy):
                        </p>

                        <ul>

                            <li>
                                <p className='text-m'>
                                    This function overrides the transfer function from the ERC404 protocol. It checks if
                                    the Bank (reserve) of ERC721 tokens is not empty. If it is not, it calls an ERC404
                                    protocol function that will transfer both ERC20 and ERC721 token/s. In case the Bank
                                    (reserve) is empty, it calls an ERC404 protocol function that will transfer only
                                    ERC20 token/s.
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p className='text-m gradient-text'>
                            Stake
                        </p>

                        <ul>

                            <li>
                                <p className='text-m'>
                                    This function allows a user to stake an available amount of ERC20 tokens. The staked
                                    tokens are transferred from the user's account to the contract. A random number is
                                    generated to determine the level (1-3) of the stake (each level represents 1 week,
                                    e.g., level 2 = 2 weeks), and by that, the contract creates a new Stake to record
                                    the staked amount, level, and timestamp.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div className="image-content-container">
                    <img src={processVerticalAsset} alt=""/>
                    <div>
                        <ul className='formatted-list'>
                            <li>
                                <p className='text-m gradient-text'>
                                    Claim
                                </p>
                                <ul>
                                    <li>
                                        <p className='text-m'>
                                            This function allows a user to claim rewards from a stake. The rewards are
                                            calculated based on the staked amount and the time staked. If the stake is
                                            older than a certain duration (1/2/3 weeks, depending on the stake level),
                                            the stake will be returned with the rewards as ERC20 and ERC721. Otherwise,
                                            only the initially staked amount will be returned + rewards in ERC20 tokens.
                                        </p>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <p className='text-m gradient-text'>
                                    Stake level randomness
                                </p>
                                <ul>
                                    <li>
                                        <p className='text-m'>
                                            The randomness function that generates a pseudo-random number to determine
                                            the level of a stake uses a combination of the current block timestamp, the
                                            previous random number, and a provided seed to generate a new seed for the
                                            keccak256 hash function. The output is then converted to a uint256 type and
                                            the modulus operation is used to ensure the random number falls within the
                                            range of 1 to 3. This number represents the level of the stake.
                                        </p>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <p className='text-m gradient-text'>
                                    ## The levels are defined as follows:
                                </p>
                                <ul>
                                    <li>
                                        <p className='text-m'>
                                            Level 1 (Gold) - requires 1 week of stake
                                        </p>
                                    </li>
                                    <li>
                                        <p className='text-m'>
                                            Level 2 (Platinum) - requires 2 weeks of stake
                                        </p>
                                    </li>
                                    <li>
                                        <p className='text-m'>
                                            Level 3 (Diamond) - requires 3 weeks of stake
                                        </p>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <p className='text-m gradient-text'>
                                    ## Differences from ERC404 Protocol
                                </p>
                                <ul>
                                    <li>
                                        <p className='text-m'>
                                            The $? contract extends the ERC404 protocol and introduces
                                            additional functionality for staking tokens and claiming rewards. It also
                                            includes a mechanism for generating random numbers, which is used to
                                            determine the level of a stake. The transfer function is overridden to
                                            handle the transfer of ERC20 tokens along with the corresponding ERC721
                                            tokens if the Bank (reserve) of ERC721 tokens is not empty."
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="center-image margin-t-l">
                    <img className='default-image' src={coinAsset} alt="Monkey"/>
                </div>

                <p className='margin-t-l text-m gradient-text'>
                    Allocation of Tokens:
                </p>
                <p className='text-m'>
                    The total 404ST supply of 10000 tokens will be allocated as follows:
                </p>
                <br/><br/>

                <ul>
                    <li className='text-m'>10% for OG NFT Claim (1000 tokens)</li>
                    <li className='text-m'>10% for team (1000 tokens)</li>
                    <li className='text-m'>10% for marketing (1000 tokens)</li>
                    <li className='text-m'>10% for staking rewards (1000tokens)</li>
                    <li className='text-m'>60% for sale (6000 tokens)</li>
                </ul>

                <br/>

                <p className='text-m gradient-text'>
                    NFT Utility:
                </p>

                <p className='text-m'>
                    Upon token purchase, users will receive ERC20 tokens, each representing ownership of one NFT. These
                    tokens can be freely traded, transferred, used as claim validators for potential rewards & DAO place
                    in revenue share for Educational Platform.
                </p>

                <br/><br/>

                <p className='text-m gradient-text'>
                    Lock DISTRIBUTIONS:
                </p>

                <p className='text-m'>
                    Users have the option to lock their tokens for a duration up to 3 weeks, during which an NFT will be
                    minted. Rewards will be claimable after the lock period of total 3 weeks, with different tiers for
                    Diamond, Platinum, and Gold holders.
                </p>

                <ul>
                    <li className='text-m'>Diamond Case: 3 weeks lock period, rewards claimable after 3 weeks</li>
                    <li className='text-m'>Platinum Case: 2 weeks lock period, rewards claimable after 2 weeks</li>
                    <li className='text-m'>Gold Case: 1 week lock period, rewards claimable after 1 week</li>
                </ul>

                <p className='text-m gradient-text'>
                    ROADMAP:
                </p>

                <p className='text-m'>
                    Upon the completion of the lock reward period, we will initiate a roadmap for further development.
                    This roadmap includes:
                </p>

                <ul>
                    <li className='text-m'>Selection of Diamond, Platinum, and Gold holders: One holder from each
                        category will be chosen as team member.
                    </li>
                    <li className='text-m'>Reward Distribution: The chosen holders will distribute 5% of the total
                        reward among themselves, and the remaining 5% will be distributed among other holders within
                        their respective tiers.
                    </li>
                    <li className='text-m'>404ST EDU Platform.</li>
                </ul>
            </div>

            <div className='stake-mine-image margin-t-l'>
                <img src={isMobile ? mobileStake : play} alt="play"/>
            </div>

            <div className="text-content-container margin-t-l">
                <p className='text-xl accent-yellow-color center-text'>
                    404ST is committed to fostering a dynamic and sustainable community ecosystem. By combining NFTs and
                    ERC20 tokens, we aim to provide users with a unique and rewarding experience. We believe that
                    through incentivizing participation and rewarding loyalty, our project will thrive and evolve into
                    the commercial world.
                </p>
            </div>

        </div>
    )
}

import {ConnectButton} from "../WalletButtons/ConnectButton/ConnectButton";
import { WalletConnectedButton } from '../WalletButtons/WalletConnectedButton/WalletConnectedButton';
import { useAccount } from 'wagmi';
import { scrollToSection } from '../../utils';

import React, { FC } from "react";
import './Header.scss'

import logo from '../../assets/logo.png';
import dextools from '../../assets/dextools.svg';
import opensea from '../../assets/icons8-opensea.svg';
import telegram from '../../assets/icons8-telegram.svg';
import twitter from '../../assets/icons8-twitterx.svg';
import {Link, useHistory} from 'react-router-dom';
import { ContractData } from '../types/types';

type Props = {
  contractData: ContractData;
};

export const Header: FC<Props> = ({ contractData }) => {
  const { isConnected, address } = useAccount();
  const history = useHistory();

  return (
    <header id='header' className='header'>
      <div className="header-logo-wrapper" onClick={() => history.push('/')}>
        <img src={logo} alt="logo" />
      </div>

      {/*// @ts-ignore*/}
      <div className="header-links-wrapper">
        <Link className="header-link" to='/lightpaper'>LIGHTPAPER</Link>
        {
          isConnected && address ?
            <WalletConnectedButton address={address}/>
            :
            <ConnectButton/>
        }
        <Link className="header-link" to='/listings'>LISTINGS</Link>
        {/*{contractData && contractData.showMint && (<div className="header-link" onClick={() => scrollToSection('mint')}>MINT</div>)}*/}
      </div>

      <nav className="header-socials-wrapper">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src={dextools} alt="Dextools"/>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src={opensea} alt="OpenSea"/>
        </a>
        <a href="https://t.me/Monkez404STportal" target="_blank" rel="noopener noreferrer">
          <img src={telegram} alt="Telegram"/>
        </a>
        <a href="https://twitter.com/404st_io" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="Twitter"/>
        </a>
      </nav>
    </header>
  )
}

import images from "./logos";


export const Listings = () => {

  return (
    <div className='page-wrapper'>
      <h1 className='center-text title-xl margin-t-l'>Listings</h1>

      <div className='margin-t-l'>
        <div className='logo-container'>
          {images.map((img, index) => (
            <div>
              <img key={index} src={img} alt={img}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
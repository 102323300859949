import { useWeb3Modal } from '@web3modal/wagmi/react'

import './ConnectButton.scss';

export const ConnectButton = ({ noMargin = false }: { noMargin?: boolean }) => {
  const { open } = useWeb3Modal();

  return (
    <div className={`connect-button-wrapper ${noMargin ? 'no-margin': ''}`}>
      <div className='gradient-border-glow'>
        <div className='gradient-border-glow-content' />
      </div>
      <div className='gradient-border-glow second'>
        <div className='gradient-border-glow-content' />
      </div>
      <div className='gradient-border'>
        <button className='button-connect' onClick={() => open()}>
          Connect Wallet
        </button>
      </div>
    </div>
  )
}
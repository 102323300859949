import React, { useEffect, useRef } from 'react';
import ScrollReveal from 'scrollreveal';
import './Roadmap.scss';


// Extend the window interface to include sr
declare global {
    interface Window {
        sr: ReturnType<typeof ScrollReveal>;
    }
}

export const Roadmap = () => {
    const itemsRef = useRef<NodeListOf<HTMLLIElement> | null>(null);

    useEffect(() => {
        window.sr = ScrollReveal();
        window.sr.reveal('.reveal', { duration: 6000 });

        itemsRef.current = document.querySelectorAll(".timeline li");

        const isElementInViewport = (el: Element) => {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        const callbackFunc = () => {
            if (itemsRef.current) {
                itemsRef.current.forEach(item => {
                    if (isElementInViewport(item)) {
                        item.classList.add("in-view");
                    }
                });
            }
        };

        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);

        return () => {
            window.removeEventListener("load", callbackFunc);
            window.removeEventListener("resize", callbackFunc);
            window.removeEventListener("scroll", callbackFunc);
        };
    }, []);

    return (
        <div className='cards-wrapper'>
            <div className="width-container">
                <div className="separator"/>
                <div className='cards-flex'>
                    <section className="timeline">
                        <ul>
                            <li>
                                <div className="reveal">
                                    <p>
                                        <div className="phase">PHASE 1 - Development</div>
                                        404ST protocol is born
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="active-phase">
                                        <div className="phase active-phase">PHASE 2 - Brand Awarenes</div>
                                        Ambassador group up
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 3 - Rezervations</div>
                                        Secure Your place
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 4 - Meme Character Announcement</div>
                                        Mint Token Character
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 5 - Token Release</div>
                                        Release of the 404ST Protocol of Hybrid NFT
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 6 - Staking</div>
                                        Stake Your tokens.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 7 - Regulation</div>
                                        We are going to be first Hybrid NFT project that will be available on centralised exchanges
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="reveal">
                                    <p className="blurry-text">
                                        <div className="phase">PHASE 8 - Listings</div>
                                        Lsitings on over 27 Exchanges
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <div className="separator"/>
            </div>
        </div>
    );
};

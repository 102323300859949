import './Hero.scss'
import heroVideo from '../../../assets/hero-gif.gif';
import arrowDown from '../../../assets/arrow-down.png';
import mintOg from '../../../assets/mint-og.png';
import { scrollToSection } from '../../../utils';
import { FC } from 'react';

type Props = {
  showMint: bigint | boolean;
};

export const Hero:FC<Props> = ({ showMint }) => (
  // @ts-ignore
  <div className='hero-section'>
    <div className="image-wrapper">
      <img src={heroVideo} alt="heroVideo" />
    </div>

    {
      showMint && (
        <div className="mint-wrapper">
          <img src={arrowDown} alt="arrowDown" />
          <img src={mintOg} alt="mintOg" onClick={() => scrollToSection('mint')} />
        </div>
      )
    }
  </div>
)
import React, { FC, useState } from "react";
import {ConnectButton} from "../WalletButtons/ConnectButton/ConnectButton";
import { WalletConnectedButton } from '../WalletButtons/WalletConnectedButton/WalletConnectedButton';
import { useAccount } from 'wagmi';
import { scrollToSection } from '../../utils';

import './HeaderMobile.scss'

import logo from '../../assets/logo.png';
import dextools from '../../assets/dextools.svg';
import opensea from '../../assets/icons8-opensea.svg';
import telegram from '../../assets/icons8-telegram.svg';
import twitter from '../../assets/icons8-twitterx.svg';
import { ContractData } from '../types/types';
import {Link} from "react-router-dom";

type Props = {
  contractData: ContractData;
};

export const HeaderMobile: FC<Props> = ({ contractData }) => {
  const { isConnected, address } = useAccount();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header id='header' className='header-mobile'>
      <nav className="header-socials-wrapper">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src={dextools} alt="Dextools"/>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer" className='hide-mobile'>
          <img src={opensea} alt="OpenSea"/>
        </a>
        <a href="https://t.me/Monkez404STportal" target="_blank" rel="noopener noreferrer">
          <img src={telegram} alt="Telegram"/>
        </a>
        <a href="https://twitter.com/404st_io" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="Twitter"/>
        </a>
      </nav>

      <div className="header-toggle-wrapper">
        <Link to='/'>
          <img src={logo} alt="logo"/>
        </Link>

        <div className="wallet">
        {
            isConnected && address ?
              <WalletConnectedButton address={address} />
              :
              <ConnectButton />
          }
        </div>

        <button type="button" className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar" />
          <div className="bar" />
          <div className="bar" />
        </button>
      </div>

      {/*// @ts-ignore*/}
      <div className={`header-links-wrapper ${isOpen ? 'open' : ''}`}>
        <Link className="header-link" to='/'>HOME</Link>
        <Link className="header-link" to='/lightpaper'>LIGHTPAPER</Link>
        <Link className="header-link" to='/listings'>LISTINGS</Link>
        {/*{contractData && contractData.showMint && (<div className="header-link" onClick={() => {*/}
        {/*  scrollToSection('mint');*/}
        {/*  closeMenu();*/}
        {/*}}>MINT</div>)}*/}
      </div>
    </header>
  )
}

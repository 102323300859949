import {useReadContract, useAccount} from "wagmi";

export const useGetAllData = (contractConfig: any, decimals = 18) => {
    const { address } = useAccount()

    // @ts-ignore
    const {data: allData} = useReadContract({
        ...contractConfig,
        functionName: 'getAllData',
        args: [address],
    })

    return allData;
}

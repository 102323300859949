import './Team.scss'

export const Team = () => (
  <div id="team" className='team-wrapper'>
    <div className="width-container">
        <div className='team-title'>
          <div className='team-title-content'>THE TEAM</div>
          <div className='team-box' />
        </div>

        <div className='team-text'>
          <b>Andrew InWood</b><br/>
          Founder & CEO, <b>404ST</b><br/>
          <br/>
          Meet Andrew, the visionary behind <b>404ST</b>, the memecoin that's shaking up the crypto world! With a knack for transforming bold ideas into reality, Andrew is a tech wizard with a passion for memes and blockchain.<br/>
          <br/>
          From coding in his garage to leading top tech startups, Andrew’s journey is legendary. He’s a pioneer in the crypto space, blending ERC20 and NFT technologies to create <b>404ST</b> – the ultimate memecoin experience. Stake your favorite meme token, earn epic NFT rewards, and join a vibrant, engaged community.<br/>
          <br/>
          Andrew is all about fun and finance, making <b>404ST</b> not just a token but a movement.<br/>
          <br/>
          Join Andrew and the <b>404ST</b> family on an adventure to the moon, driven by memes, dreams, and the power of blockchain.<br/>
          <br/>
          <div className='team-title-content'>The future is meme-tastic with 404ST!</div>
        </div>
    </div>
  </div>
)
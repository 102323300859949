import React from 'react';
import {Hero} from "./Hero/Hero";
import {Roadmap} from "./Roadmap/Roadmap";
import {StakeMine} from "./StakeMine/StakeMine";
import {Team} from "./Team/Team";
import {FC} from "react";
import {ContractData} from "../types/types";

import './Home.scss';

type Props = {
    contractData: ContractData;
};

const Home: FC<Props> = ({ contractData }) => {
  return (
      // @ts-ignore
          <div className="home-wrapper">
                {/*<Hero showMint={contractData && contractData.showMint} />*/}
                <Hero showMint={false} />
                {/*<Rezervation contractData={contractData} />*/}
                {/*{contractData && contractData.showMint && <Mint contractData={contractData} />}*/}
                <StakeMine />
                {/*<Cards />*/}
                <Roadmap />
                <Team />
          </div>
      );
};

export default Home;

import nftAbi from "../env/nftAbi.json";
import stAbi from "../env/404stAbi.json";
import claimAbi from "../env/claimAbi.json";

export const nftContractConfig = {
    abi: nftAbi,
    address: '0x676e02952d76a6205E0b1a2109d8336C5B0e6C44',
    chainId: 11155111,
    // chainId: mainnet.id,
}

export const tokenContractConfig = {
    abi: stAbi,
    address: '0x721C265C3D3Dbf0aA778886a2d0957EE1b57ECC0',
    chainId: 11155111,
    // chainId: mainnet.id,
}

export const claimableContractConfig = {
    abi: claimAbi,
    address: '0xc6cA89F88Ff2B2F60C2F46aE7aA37f1E192d53B0',
    chainId: 11155111,
    // chainId: mainnet.id,
}

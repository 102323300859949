import './Page404.scss';
import { Link } from 'react-router-dom';

export const Page404 = () => (
  <div className="page-404-wrapper">
    <div className="text"><p>404</p></div>
    <div className="container">
      <div className="caveman">
        <div className="leg">
          <div className="foot">
            <div className="fingers"></div>
          </div>
        </div>
        <div className="leg">
          <div className="foot">
            <div className="fingers"></div>
          </div>
        </div>
        <div className="shape">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
        <div className="head">
          <div className="eye">
            <div className="nose"></div>
          </div>
          <div className="mouth"></div>
        </div>
        <div className="arm-right">
          <div className="club"></div>
        </div>
      </div>
      <div className="caveman">
        <div className="leg">
          <div className="foot">
            <div className="fingers"></div>
          </div>
        </div>
        <div className="leg">
          <div className="foot">
            <div className="fingers"></div>
          </div>
        </div>
        <div className="shape">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
        <div className="head">
          <div className="eye">
            <div className="nose"></div>
          </div>
          <div className="mouth"></div>
        </div>
        <div className="arm-right">
          <div className="club"></div>
        </div>
      </div>
    </div>
  </div>
);